<template>
  <div v-if="match" class="match-card">
    <v-overlay absolute :value="lowlight"></v-overlay>
    <!-- BRACKET_SKINNY -->
    <div :class="{hide: match.isBye}" class="bracket-match" :id="`brackt-match${this.matchIn.number}`">
      <v-card v-if="useLogos">
        <div class="d-flex align-center">
          <v-img
            v-if="!!homeLogo"
            :src="homeLogo"
            max-height="22"
            width="22"
            max-width="22"
            contain
          ></v-img>
          <div class="bracket-team home pl-0">
            <span
              class="seed text-right"
              v-if="!hideSeeds"
            >{{homeSeed}}</span>
            <span
              v-if="homeDisplayName"
              class="text-truncate team"
              :class="{'font-weight-bold': match.winner === 'home', waiting: !match.homeTeam}"
            >
              {{homeDisplayName}}
            </span>
            <span v-else v-html="'&nbsp;'" class="team"></span>
            <span class="score" :class="{'font-weight-bold': match.winner === 'home'}">{{score.home}}</span>
          </div>
        </div>
        <div class="bracket-team away pl-0">
          <div class="d-flex align-center">
            <v-img
              v-if="!!awayLogo"
              :src="awayLogo"
              max-height="22"
              width="22"
              max-width="22"
              contain
            ></v-img>
            <span
              class="seed text-right"
              v-if="!hideSeeds"
            >{{awaySeed}}</span>
            <span
              v-if="awayDisplayName"
              class="text-truncate team"
              :class="{'font-weight-bold': match.winner === 'away', waiting: !match.awayTeam}"
            >
              {{awayDisplayName}}
            </span>
            <span v-else v-html="'&nbsp;'" class="team"></span>
            <span class="score" :class="{'font-weight-bold': match.winner === 'away'}">{{score.away}}</span>
          </div>
        </div>
      </v-card>
      <v-card v-else flat tile>
        <div class="bracket-team home">
          <span
            class="seed text-right"
            v-if="!hideSeeds"
          >{{homeSeed}}</span>
          <span
            v-if="homeDisplayName"
            class="text-truncate team"
            :class="{'font-weight-bold': match.winner === 'home', waiting: !match.homeTeam}"
          >
            {{homeDisplayName}}
          </span>
          <span v-else v-html="'&nbsp;'" class="team"></span>
          <span class="score" :class="{'font-weight-bold': match.winner === 'home'}">{{score.home}}</span>
        </div>
        <div class="bracket-team away">
          <span
            class="seed text-right"
            v-if="!hideSeeds"
          >{{awaySeed}}</span>
          <span
            v-if="awayDisplayName"
            class="text-truncate team"
            :class="{'font-weight-bold': match.winner === 'away', waiting: !match.awayTeam}"
          >
            {{awayDisplayName}}
          </span>
          <span v-else v-html="'&nbsp;'" class="team"></span>
          <span class="score" :class="{'font-weight-bold': match.winner === 'away'}">{{score.away}}</span>
        </div>
      </v-card>
      <div class="bracket-label bottom" v-if="refTeam || (streaming && hasStreams) || showNoRef || showDivision">
        <div v-if="streaming && hasStreams" class="text-center" :key="tournament.streams.length">
          <add-stream-url-dialog
            v-if="streaming"
            :bracketId="bracket.id"
            :matchNumber="matchIn.number"
            :complete="matchIn.complete"
            :forBracket="true"
          ></add-stream-url-dialog>
        </div>
        <template v-if="refTeam">
          <span class="text-truncate" v-if="refDisplay.lastNames && !division.useTeamNames && division.numOfPlayers < 3">
            Ref: {{refDisplay.lastNames | slashArray}}
          </span>
          <span class="text-truncate" v-else>
            {{refDisplay.name ? `Ref: ${refDisplay.name}` : loserText}}
          </span>
        </template>
        <template v-else-if="showNoRef">
          <span class="text-truncate">No Ref Assigned</span>
        </template>
        <template v-else-if="showDivision">
          <span class="text-truncate">{{division.name}}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const AddStreamUrlDialog = () => import('@/components/LiveStream/AddStreamUrlDialog')

export default {
  props: [
    'matchIn',
    'divisionId',
    'showMap',
    'showByes',
    'bracket',
    'showFooter',
    'lineText',
    'mapper',
    'map',
    'showNoRef',
    'highlight',
    'lowlight',
    'showDivision'
  ],
  data () {
    return {
      loading: false,
      winLoss: false,
      editableMatch: null,
      liveScoring: false,
      homeLine: null,
      awayLine: null,
      toLine: null,
      editing: false,
      all: false,
      swapMe: null,
      swapWith: null,
      poolRefWindow: 1,
      askComplete: false,
      settings: null,
      settingsOg: null
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'view',
      'tournament',
      'getTeam',
      'getDivision',
      'user'
    ]),
    useLogos () {
      return this.division && this.division.useLogos
    },
    streaming () {
      return this.tournament && this.tournament.props.includes('streaming')
    },
    courtOrder () {
      return this.hideTimes && this.match.courtOrder
    },
    isRef () {
      return this.user && this.tournament && this.tournament.refIds.includes(this.user.id)
    },
    matchAdmin () {
      return (this.admin || this.isRef) && !this.view.public
    },
    division () {
      return this.tournament.getDivisionFromMatch(this.matchIn.id)
    },
    round () {
      return this.tournament.getDayFromMatch(this.matchIn.id)
    },
    roundJProps () {
      return this.$store.getters.getRoundJProps(this.round.id)
    },
    hideTimes () {
      const r = this.$store.getters.getRound(this.round.id)
      return r && r.hideTimes
    },
    hideSeeds () {
      return this.tournament.isLeague || (this.roundJProps && this.roundJProps.noSeeds) || this.$store.getters.getDivisionJProp(this.division.id, 'hideSeed')
    },
    hideRefs () {
      return this.roundJProps && this.roundJProps.noRefs
    },
    startTime () {
      if (!this.match.startTime) return null
      const t = this.match.startTime.format('h:mm A')
      const d = this.match.startTime.format('ddd h:mm A')
      return this.tournament.isOneDay ? t : d
    },
    awayTeam () {
      return this.match.awayTeam ? this.getTeam(this.match.awayTeam.teamId) : null
    },
    homeTeam () {
      return this.match.homeTeam ? this.getTeam(this.match.homeTeam.teamId) : null
    },
    refTeam () {
      return this.match.refTeam ? this.getTeam((this.match.refTeam.teamId || this.match.refTeam.id)) : this.match.manualRefTeam
    },
    bothTeams () {
      return !!this.homeTeam && !!this.awayTeam
    },
    displayFooter () {
      return this.showFooter && !this.match.complete && ((this.match.startTime && !this.hideTimes) || this.match.court || this.refDisplay.name)
    },
    match () {
      return this.matchIn
    },
    games () {
      return this.matchIn.publicGames
    },
    refDisplay () {
      return this.match.manualRefTeam ? {
        name: this.match.manualRefTeam.name,
        lnames: this.match.manualRefTeam.lastNames
      } : {
        name: this.refTeam ? this.refTeam.name : this.courtOrder && this.match.courtOrder > 1 ? `Loser match ${this.match.courtOrder - 1}` : this.match.refMap,
        slot: this.refTeam ? this.refTeam.slot : null,
        lastNames: this.refTeam ? this.refTeam.lastNames : null
      }
    },
    swap () {
      if (!this.bracket) return
      const a = []
      this.match.homeTeam && a.push(this.match.homeTeam.teamId)
      this.match.awayTeam && a.push(this.match.awayTeam.teamId)
      return {
        teams: this.bracket.swappableTeams.filter(f => a.includes(f.teamId)),
        replacements: this.swapMe ? this.bracket.swappableTeams.filter(f => f.teamId !== this.swapMe.teamId) : []
      }
    },
    status () {
      return this.match.status
    },
    isBye () {
      return this.match.isBye
    },
    homeDisplayName () {
      if (this.showMap) return (this.map && this.map.home) ? this.map.home : this.homeMap
      if (this.homeTeam && this.useLogos) return this.homeLNames
      if (this.homeTeam) return this.homeTeam.players.length <= 2 && this.division.numOfPlayers <= 2 && !this.division.useTeamNames ? this.homeLNames : this.homeTeam.name
      if (!this.feederWasBye(this.match.homeFeeder) && !this.skinny) return this.homeMap
      if (!this.feederIsSameBracket(this.match.homeFeeder) && this.skinny) return this.homeMap
      return this.feederWasBye(this.match.homeFeeder) || !this.match.round ? this.homeMap : ''
    },
    homeLNames () {
      // return this.homeTeam && this.homeTeam.players.sort(firstBy('lastName')).map(m => m.lastName).join('/')
      return this.homeTeam && this.homeTeam.players.map(m => m.lastName).join('/')
    },
    homeSeed () {
      return this.match.homeTeam ? this.match.homeTeam.seed : this.match.homeSeed || ''
    },
    homeMap () {
      // return this.match.homeTeam && (this.feederWasBye(this.match.homeFeeder) || !this.feederIsSameBracket(this.match.homeFeeder)) ? this.match.homeTeam.adminRep : this.match.homeMap
      return this.match.homeMap
    },
    homeLogo () {
      return this.useLogos && this.homeTeam && this.homeTeam.logo
    },
    awayDisplayName () {
      if (this.showMap) return (this.map && this.map.away) ? this.map.away : this.awayMap
      if (this.awayTeam && this.useLogos) return this.awayLNames
      if (this.awayTeam) return this.awayTeam.players.length <= 2 && this.division.numOfPlayers <= 2 && !this.division.useTeamNames ? this.awayLNames : this.awayTeam.name
      if (!this.feederWasBye(this.match.awayFeeder) && !this.skinny) return this.awayMap
      if (!this.feederIsSameBracket(this.match.awayFeeder) && this.skinny) return this.awayMap
      return this.feederWasBye(this.match.awayFeeder) || !this.match.round ? this.awayMap : ''
    },
    awayLNames () {
      // return this.awayTeam && this.awayTeam.players.sort(firstBy('lastName')).map(m => m.lastName).join('/')
      return this.awayTeam && this.awayTeam.players.map(m => m.lastName).join('/')
    },
    awaySeed () {
      return this.match.awayTeam ? this.match.awayTeam.seed : this.match.awaySeed || ''
    },
    awayMap () {
      // return this.match.awayTeam && (this.feederWasBye(this.match.awayFeeder) || !this.feederIsSameBracket(this.match.awayFeeder)) ? this.match.awayTeam.adminRep : this.match.awayMap
      return this.match.awayMap
    },
    awayLogo () {
      return this.useLogos && this.awayTeam && this.awayTeam.logo
    },
    teams () {
      const t = []
      if (this.match.homeTeam) {
        t.push({
          slot: this.match.homeTeam.slot,
          team: this.homeTeam,
          winner: this.match.winner === 'home',
          map: 'home',
          seed: this.homeSeed
        })
      } else {
        t.push({
          holder: this.match.homeMap
        })
      }
      if (this.match.awayTeam) {
        t.push({
          slot: this.match.awayTeam.slot,
          team: this.awayTeam,
          winner: this.match.winner === 'away',
          map: 'away',
          seed: this.awaySeed
        })
      } else {
        t.push({
          holder: this.match.awayMap
        })
      }
      return t
    },
    toCrossover () {
      return this.bracket && this.bracket.type === 'DOUBLE_ELIM_CROSSOVER' && (this.matchIn.number === 249 || this.matchIn.number === 250)
    },
    toFinals () {
      return this.bracket && this.bracket.type === 'DOUBLE_ELIM' && this.matchIn.number === 253
    },
    break () {
      return this.$vuetify.breakpoint.name
    },
    loserTextOverride () {
      return this.roundJProps && this.roundJProps.loserText && this.roundJProps.loserText[this.match.round]
    },
    loserStr () {
      return this.loserTextOverride || (this.match.loserMap ? (this.match.loserMap.contend) ? this.match.loserMap.low < 5 ? '' : `Loser to ${this.bracket.losersName} bracket - ${this.match.loserMap.to > 0 ? this.match.loserMap.to : ''}` : `Loser finishes ${this.$options.filters.ordinal(this.match.loserMap.to)}` : '')
    },
    loserText () {
      const seed = this.bracket && this.bracket.isChallenge && this.match.loserMap && this.match.loserMap.low
      return seed ? `${this.loserTextOverride || 'Loser to'} C${seed}` : this.loserStr
    },
    score () {
      if (this.match.games.length === 1) {
        return this.match.games[0]._winner ? {
          home: this.match.games[0]._winner === 'home' ? 'W' : 'L',
          away: this.match.games[0]._winner === 'home' ? 'L' : 'W'
        } : {
          home: this.match.games[0].home,
          away: this.match.games[0].away
        }
      }
      const show = this.match.homeSetWins || this.match.awaySetWins
      return {
        home: show ? this.match.homeSetWins : '',
        away: show ? this.match.awaySetWins : ''
      }
    },
    advanceText () {
      if (this.toCrossover || this.toFinals || this.lineText) {
        let text = this.lineText || (this.toCrossover ? 'To Semifinals' : 'To Finals')
        if (this.bracket) {
          const pre = this.bracket.isChallenge ? 'C' : this.bracket.isQualifier ? 'Q' : false
          const seed = this.match.loserMap && this.match.loserMap.hi
          if (pre && seed) {
            text = `${text} ${pre}${seed}`
          }
        }
        return text
      }
      return false
    },
    hasStreams () {
      return this.tournament &&
        this.tournament.streams &&
        this.tournament.streams.filter(f => !f.deleted && f.matchId === this.matchIn.number && f.bracketId && f.bracketId === this.bracket.id).length
    }
  },
  methods: {
    feederWasBye (feederN) {
      const match = this.bracket && this.bracket.matches.find(f => f.number === feederN)
      return match && match.isBye
    },
    feederIsSameBracket (feederN) {
      const match = this.bracket && this.bracket.matches.find(f => f.number === feederN)
      return match && match.isWinners === this.match.isWinners
    },
    feederExists (feederN) {
      const matchI = this.bracket && this.bracket.matches.findIndex(f => f.number === feederN)
      return matchI > -1
    },
    mapRefs () {
      if (this.match && !this.match.refTeam && this.match.refTeamId) {
        this.match.refTeam = this.getTeam(this.match.refTeamId)
      }
      if (this.match && !this.match.manualRefTeam && this.match.manualRefId) {
        this.match.manualRefTeam = this.getTeam(this.match.manualRefId)
      }
    }
  },
  watch: {
    matchIn: function (val) {
      this.mapRefs()
      if (val && val.games) {
        this.winLoss = this.matchIn.games.filter(g => g._winner).length > 0
      }
    },
    showByes: function (n, o) {
      if (this.homeLine) {
        if (n) {
          this.homeLine.show()
        } else {
          this.feederWasBye(this.matchIn.homeFeeder) && this.homeLine.hide()
        }
      }
      if (this.awayLine) {
        if (n) {
          this.awayLine.show()
        } else {
          this.feederWasBye(this.matchIn.awayFeeder) && this.awayLine.hide()
        }
      }
    },
    displayFooter: function (n, o) {
      if (n !== o) this.$emit('size-change')
    },
    'match.refTeamId': function (n, o) {
      if (n) this.match.refTeam = this.getTeam(this.match.refTeamId)
    },
    'match.manualRefTeam': function (n, o) {
      if (n) this.match.refTeam = null
    },
    'match.refTeam': function (n, o) {
      if (n) this.match.manualRefTeam = null
    }
  },
  mounted () {
    this.mapRefs()
  },
  components: {
    AddStreamUrlDialog
  }
}
</script>

<style scoped>
.no-border {
  border: none !important;
}
.match-card {
  font-size: 14px;
}
.ref,
.match-card >>> td {
  font-size: 13px;
}
.tiny {
  font-size: x-small;
}
.bordered {
  border: 1px solid rgba(0,0,0,0.12);
}
.footer td {
  height: auto;
  font-size: 11px;
  padding: 0 !important;
}
.t2 td:first-child,
.t2 >>> th:first-child {
  padding: 0 6px !important;
}
.winner {
  font-weight: 700;
}
.no-anim {
  transition: 0s;
}
@media screen and (max-width: 600px) {
  .t2 td:first-child,
  .t2 >>> th:first-child {
    padding: 0 6px !important;
  }
  .t2 >>> td>div {
    white-space: nowrap;
  }
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;
}
.bracket-match {
  font-size: 0.85em;
  line-height: 1.25em;
}
.bracket-match div {
  min-height: 1.25em;
}
.bracket-team {
  padding: 0.4em;
  background-color: #fff;
  display: flex;
}
.bracket-team.away {
  border-top: 2px solid #eee;
}
.bracket-team .seed {
  font-size: 0.85em;
  width: 1.5em;
  padding-right: 0.25em;
  color: #a3a3a3;
}
.bracket-team .team {
  width: 100%;
}
.bracket-team .score {
  width: 2em;
  text-align: right;
}
.bracket-label {
  font-size: 0.9em;
  margin-left: 0.2em;
}
.bracket-label.bottom {
  margin-top: 0.2em;
}
.waiting {
  color: #BDBDBD;
  font-style: italic;
}
</style>
