<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <!-- BREADCRUMBS -->
    <v-row dense>
      <v-col cols="12">
        <v-breadcrumbs class="py-0" :class="{'pl-0': $vuetify.breakpoint.smAndUp}" :items="breadcrumbs">
          <v-icon slot="divider" class="xsmall">fas fa-chevron-right</v-icon>
          <template slot="item" slot-scope="props">
            <v-btn
              color="color3"
              class="pa-0 ma-0 xsmall"
              style="min-width: 0"
              text
              :to="props.item.to"
              exact
              small
              :disabled="props.item.disabled"
            >{{props.item.text}}</v-btn>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <!-- MAIN CONTENT -->
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>{{tournament.name}} Courts</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-window v-model="window">
              <v-window-item :key="0">
                <v-row dense>
                  <v-btn color="color3 color3Text--text" @click.stop="window = 1">
                    Got to the manager I know
                  </v-btn>
                  <advanced-court-manager v-if="user && user.id === 1"></advanced-court-manager>
                </v-row>
              </v-window-item>
              <v-window-item :key="1">
                <template v-if="window===1">
                  <v-row dense class="justify-end"><!-- Buttons -->
                    <court-grouper :tournament="tournament" v-if="view.admin"></court-grouper>
                    <v-btn color="color3" text x-small @click.stop="displayCourts = !displayCourts">
                      Toggle {{displayCourts ? 'Times' : 'Courts'}}
                    </v-btn>
                  </v-row>
                  <v-row dense> <!-- DAY SELECTOR -->
                    <v-col cols="6">
                      <v-select
                        :items="days"
                        v-model="day"
                        label="Day"
                        color="color3"
                        item-color="color3"
                        attach
                        :solo-inverted="days.length > 1"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="divisions"
                        v-model="division"
                        label="Division"
                        color="color3"
                        item-color="color3"
                        attach
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6" v-if="!!groups">
                      <v-select
                        :items="groups"
                        v-model="selectedGroups"
                        label="Court Groups"
                        color="color3"
                        item-color="color3"
                        attach
                        item-text="name"
                        return-object
                        multiple
                        clearable
                        :menu-props="{ closeOnContentClick: true }"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        :items="teams"
                        v-model="selectedTeams"
                        label="Team"
                        color="color3"
                        item-color="color3"
                        attach
                        item-text="name"
                        item-value="id"
                        multiple
                        chips deletable-chips small-chips
                        clearable
                        :menu-props="{ closeOnContentClick: true }"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="courtChips > 20"> <!-- COURT SELECTOR -->
                    <v-col cols="12" v-if="displayCourts">
                      <v-chip
                        v-for="court in courtChips"
                        :key="court.text"
                        @click.stop="toggleCourt(court.val)"
                      >
                        <v-icon small class="mr-2" v-if="!court.hide">far fa-eye</v-icon>
                        <v-icon small class="mr-2" v-else>far fa-eye-slash</v-icon>
                        {{court.text}}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" v-else>
                      <v-chip
                        v-for="time in timeChips"
                        :key="time.text"
                        @click.stop="toggleTime(time.val)"
                      >
                        <v-icon small class="mr-2" v-if="!time.hide">far fa-eye</v-icon>
                        <v-icon small class="mr-2" v-else>far fa-eye-slash</v-icon>
                        {{time.text}}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-slide-y-transition hide-on-leave mode="out-in">
                    <div dense v-if="displayCourts && courtTimelines" :key="day"> <!-- COURTS -->
                        <court-name-editor
                          ref="courtNameEdit"
                          :court=" selectedCourtName && selectedCourtName.court"
                          :courtI="selectedCourtName && selectedCourtName.i"
                          v-if="tournament.isLeague"
                        ></court-name-editor>
                        <v-data-iterator
                          :items="courtTimelines"
                          item-key="name"
                          :items-per-page="8"
                        >
                          <template v-slot:default="{ items }">
                            <v-row>
                              <v-col
                                v-for="(tl, i) in items"
                                :key="`court${tl.court}`"
                                cols="12" sm="6" md="4" lg="3"
                              >
                                <v-card height="100%" color="grey lighten-4"> <!-- COURT CARD -->
                                  <v-card-title class="color2 color2Text--text title" dense>
                                    <div class="title">Court {{tl.court}}</div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      v-if="tournament.isLeague"
                                      color="color2Text"
                                      icon
                                      @click.stop="selectCourt(tl.court, i)"
                                    >
                                      <v-icon small>fas fa-pencil</v-icon>
                                    </v-btn>
                                    <v-btn
                                      color="color1 color1Text--text"
                                      small fab
                                      :to="{name: 'court-timeline', params: { tournamentId: tournament.id, court: tl.court }}"
                                    >
                                      <v-icon>fas fa-caret-right</v-icon>
                                    </v-btn>
                                  </v-card-title>
                                  <v-card-text class="pt-0 pl-0">
                                    <v-timeline dense class="pa-0 ml-n6">
                                      <v-timeline-item
                                        v-for="(t, x) in tl.times"
                                        :key="`${tl.court}-time${x}`"
                                        :color="!t.matches.length ? 'grey' : t.matches.length > 1 ? 'warning' : t.matches[0].complete ? 'success' : 'error'"
                                        small
                                        style="min-height: 34px"
                                        class="pa-0"
                                      >
                                        <v-row class="ml-n9">
                                          <v-col class="d-flex align-center font-weight-black" cols="3" >
                                            <fit-text>{{t.time}}</fit-text>
                                          </v-col>
                                          <v-col cols="9">
                                            <v-card
                                              v-if="t.matches.length"
                                              :color="t.matches.length > 1 ? 'warning' : t.matches[0].complete ? 'success' : 'error'"
                                              class="pa-0"
                                            >
                                              <template v-if="!tournament.isLeague">
                                                <v-card-text
                                                  v-for="(m, i) in t.matches"
                                                  :key="`${tl.court}-time${x}-match${i}`"
                                                  :class="`${m.complete ? 'success' : 'error'} white--text pa-1`"
                                                  @click.stop="selectMatch(m)"
                                                >
                                                  <div>
                                                    <div><span class="caption">{{m.division}} </span><strong>{{m.nameArray | pipeArray}}</strong></div>
                                                  </div>
                                                </v-card-text>
                                              </template>
                                              <template v-if="tournament.isLeague">
                                                <mini-match-card
                                                  :matchIn="m.m"
                                                  v-for="(m, i) in t.matches"
                                                  :key="`${tl.court}-time${x}-match${i}`"
                                                  :class="`${m.complete ? 'success' : 'error'} white--text pa-0`"
                                                  @click.native="selectMatch(m)"
                                                  :showDivision="tournament.publicDivisions.length > 1"
                                                  :lowlight="selectedTeams.length > 0 && checkLowlight(m)"
                                                ></mini-match-card>
                                              </template>
                                            </v-card>
                                            <v-card
                                              v-else-if="tournament.isLeague"
                                              height="49"
                                            >
                                              <v-card-text
                                                :key="`${tl.time}-time${x}-match0`"
                                                @click.stop="createMatch(tl, t)"
                                                class="pa-1 fill-height d-flex justify-center align-center"
                                              >
                                                <div>
                                                  <div>Open Court</div>
                                                </div>
                                              </v-card-text>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-timeline-item>
                                    </v-timeline>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                    </div>
                    <v-row dense v-else> <!-- TIMES -->
                      <v-col
                        v-for="tl in timeTimelines"
                        :key="`time${tl.time}`"
                        cols="12" sm="6" md="4" lg="3"
                      >
                        <v-card height="100%" color="grey lighten-4"> <!-- TIME CARD -->
                          <v-card-title class="color2 color2Text--text title" dense>
                            <div class="title">{{tl.time}}</div>
                          </v-card-title>
                          <v-card-text class="pt-0 pl-0">
                            <v-timeline dense class="pa-0 ml-n6">
                              <v-timeline-item
                                v-for="(t, x) in tl.courts"
                                :key="`${tl.time}-time${x}`"
                                :color="!t.matches.length ? 'grey' : t.matches.length > 1 ? 'warning' : t.matches[0].complete ? 'success' : 'error'"
                                small
                                style="min-height: 34px"
                                class="pa-0"
                              >
                                <v-row class="ml-n9">
                                  <v-col class="d-flex align-center font-weight-black" cols="3" >
                                    <fit-text>{{t.court && t.court.startsWith('Court') ? '' : 'Court '}}{{t.court || 'Unassigned'}}</fit-text>
                                  </v-col>
                                  <v-col cols="9">
                                    <v-card
                                      v-if="t.matches.length"
                                      :color="t.matches.length > 1 ? 'warning' : t.matches[0].complete ? 'success' : 'error'"
                                      class="pa-0"
                                    >
                                      <template v-if="tournament.isTournament">
                                        <v-card-text
                                          v-for="(m, i) in t.matches"
                                          :key="`${tl.court}-time${x}-match${i}`"
                                          :class="`${m.complete ? 'success' : 'error'} white--text pa-1`"
                                          @click.stop="selectMatch(m)"
                                        >
                                          <div>
                                            <div><span class="caption">{{m.division}} </span><strong>{{m.nameArray | pipeArray}}</strong></div>
                                          </div>
                                        </v-card-text>
                                      </template>
                                      <template v-if="tournament.isLeague">
                                        <mini-match-card
                                          :matchIn="m.m"
                                          v-for="(m, i) in t.matches"
                                          :key="`${tl.court}-time${x}-match${i}`"
                                          :class="`${m.complete ? 'success' : 'error'} white--text pa-0`"
                                          @click.native="selectMatch(m)"
                                          :showDivision="tournament.publicDivisions.length > 1"
                                        ></mini-match-card>
                                      </template>
                                    </v-card>
                                    <v-card
                                      v-else-if="tournament.isLeague"
                                      height="65"
                                    >
                                      <v-card-text
                                        :key="`${tl.time}-time${x}-match0`"
                                        @click.stop="createMatch(tl)"
                                        class="pa-1 fill-height d-flex justify-center align-center"
                                      >
                                        <div>
                                          <div>Open Court</div>
                                        </div>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-timeline-item>
                            </v-timeline>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-slide-y-transition>
                </template>
              </v-window-item>
            </v-window>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <match-import-dialog :tournament="tournament" v-if="tournament.isLeague"></match-import-dialog>
    <v-btn color="color3" text @click.stop="onDownloadClick" v-if="tournament.isLeague">Export Schedule</v-btn>
    <!-- Match Up Creator -->
    <v-dialog
      v-model="matchCreatotDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>Match Up Creator</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color2 color2Text--text"
            small fab
            @click.stop="matchCreatotDialog = false"
            :disabled="persist"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-1">
          <match-up-creator
            :day="day"
            :court="selectedCourt"
            :time="selectedSlot"
            :open="matchCreatotDialog"
            @loading-change="onLoadingChange"
            @saved="matchCreatotDialog = false"
            :availSlots="availSlots"
          ></match-up-creator>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- MATCH DIALOG -->
    <v-dialog
      v-if="selectedMatch"
      v-model="matchDialog"
      scrollable
      :persistent="false"
      width="90vw"
      max-width="800px"
      transition="dialog-transition"
    >
      <full-match-card
        :key="`${selectedMatch.number}-${selectedMatch.id}`"
        style="width: 100%"
        :matchIn="selectedMatch.m"
        :pool="selectedMatch.ref.pool"
        :bracket="selectedMatch.ref.bracket"
        :bracketCard="!!selectedMatch.ref.bracket"
        :division="selectedDivision"
        :round="selectedDay"
        @match-saved="onMatchSaved"
        :jump="true"
      ></full-match-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import RouteMixin from '@/Mixins/RouteMixin.js'
import MiniMatchCard from '@/components/Tournament/Match/MiniMatchCard'
import FullMatchCard from '@/components/Tournament/Match/FullMatchCard'
import moment from 'moment'
import flatten from '../../../helpers/ArrayFlatten'
import { firstBy } from 'thenby'
import Papa from 'papaparse'
const MatchUpCreator = () => import('@/components/Tournament/Courts/MatchUpCreatorWindows')
const MatchImportDialog = () => import('@/components/Tournament/Courts/MatchImportDialog')
const CourtNameEditor = () => import('@/components/Tournament/Courts/CourtNameEditor')
const CourtGrouper = () => import('@/components/Tournament/Courts/CourtGrouper')
const AdvancedCourtManager = () => import('@/components/Tournament/Courts/AdvancedCourtManager')
// const sortFn = (a, b) => {
//   return a === b ? 0 : a < b ? -1 : 1
// }
const mysort = (a, b) => {
  const c = moment(a, 'LT').unix()
  const d = moment(b, 'LT').unix()
  return c > d ? 1 : c < d ? -1 : 0
}

export default {
  mixins: [RouteMixin],
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Tournament Home',
          to: { name: 'tournament-brochure' },
          exact: true,
          disabled: false
        },
        {
          text: 'Courts',
          exact: true,
          disabled: true
        }
      ],
      hideCourts: [],
      hideTimes: [],
      day: null,
      division: null,
      selectedTeams: [],
      matchDialog: false,
      editable: null,
      selectedMatch: null,
      loading: false,
      courtAdd: false,
      search: null,
      mSearch: null,
      startTime: null,
      court: null,
      showTeams: false,
      displayCourts: true,
      matchCreatotDialog: false,
      selectedCourt: null,
      selectedSlot: null,
      persist: false,
      selectedGroups: [],
      window: 1,
      selectedCourtName: null
    }
  },
  computed: {
    ...mapGetters(['tournament', 'view', 'color1IsDark', 'user']),
    fullTimeline () {
      return this.tournament.isLeague
        ? this.division
          ? this.tournament.leagueTimeline.filter(f => f.division === this.division)
          : this.tournament.leagueTimeline : this.division
          ? this.tournament.fullTimeline.filter(f => f.division === this.division)
          : this.tournament.fullTimeline
    },
    matches () {
      return this.tournament.matches
    },
    teams () {
      return this.tournament.activeTeams
    },
    courts () {
      let courts = [...this.fullTimeline.filter(f => f.court).map(m => m.court)].filter(f => !this.hideCourts.includes(f))
      // courts = [...new Set(courts)].sort(function (a, b) {
      //   return sortFn(+a.replace(/\D/g, ''), +b.replace(/\D/g, ''))
      // })
      courts = [...new Set(courts)]
      !this.hideCourts.includes('Unassigned') && this.fullTimeline.filter(f => !f.court && f.day === this.day).length && courts.unshift('Unassigned')
      return courts
    },
    groups () {
      return this.tournament.jProps && this.tournament.jProps.courtGroups
    },
    allCourts () {
      let courts = [...this.fullTimeline.filter(f => f.court).map(m => m.court)]
      courts = [...new Set(courts)].sort(firstBy(Number))
      const unass = this.fullTimeline.filter(f => !f.court && f.day === this.day)
      unass.length && courts.unshift('Unassigned')
      return courts
    },
    courtChips () {
      return this.allCourts.map(m => {
        return {
          text: m === 'Unassigned' ? m : 'Court ' + m,
          hide: this.hideCourts.includes(m),
          val: m
        }
      })
    },
    timeChips () {
      return this.allTimes.map(m => {
        return {
          text: m,
          hide: this.hideTimes.includes(m),
          val: m
        }
      })
    },
    days () {
      return [...new Set(this.fullTimeline.filter(f => f.day).map(m => m.day))]
    },
    times () {
      const t = [...new Set(this.fullTimeline.filter(f => f.time).map(m => m.time))].filter(f => !this.hideTimes.includes(f)).sort(mysort)
      // !this.hideTimes.includes('Unassigned') && this.fullTimeline.filter(f => !f.time && f.day === this.day) && t.unshift('Unassigned')
      return t
    },
    allTimes () {
      const t = [...new Set(this.fullTimeline.filter(f => f.time).map(m => m.time))].sort(mysort)
      // this.fullTimeline.filter(f => !f.time && f.day === this.day) && t.unshift('Unassigned')
      return t
    },
    availSlots () {
      return this.courtTimelines && flatten(this.courtTimelines.map(ct => {
        return ct.times.filter(f => f.matches.length === 0).map(t => {
          return {
            court: ct.court,
            time: t.time,
            text: `Court ${ct.court} @ ${t.time}`
          }
        })
      }))
    },
    courtTimelines () {
      return this.courts && this.day && this.courts.map(m => {
        const matches = m === 'Unassigned' ? this.fullTimeline.filter(f => !f.court && f.day === this.day) : this.fullTimeline.filter(f => f.court === m && f.day === this.day && f.m)
        const times = [...new Set(this.fullTimeline.filter(f => f.day === this.day).map(m => m.time))].sort(mysort)
        return {
          court: m,
          times: times.map(t => {
            return {
              time: t,
              matches: matches.filter(f => f.time === t)
            }
          }).sort(mysort)
        }
      })
    },
    timeTimelines () {
      return this.times && this.day && this.times.map(m => {
        const matches = m === 'Unassigned' ? this.fullTimeline.filter(f => !f.time && f.day === this.day) : this.fullTimeline.filter(f => f.time === m && f.day === this.day && f.m)
        const courts = [...new Set(this.fullTimeline.filter(f => f.day === this.day).map(m => m.court))]
        return {
          time: m,
          courts: courts.map(t => {
            return {
              court: t,
              matches: matches.filter(f => f.court === t)
            }
          })
        }
      }).sort(mysort)
    },
    divisions () {
      const d = this.tournament.publicDivisions.map(d => {
        return {
          text: d.name,
          value: d.name
        }
      })
      return [
        { text: 'All', value: null },
        ...d
      ]
    },
    dirty () {
      return this.selectedMatch && this.editable && JSON.stringify(this.selectedMatch.m.dto) !== JSON.stringify(this.editable.dto)
    },
    dualStatus () {
      const a = this.tournament.publicDivisions.length
      const b = this.tournament.publicDivisions.filter(f => f.isDuals).length
      return b === 0 ? 'none' : b === a ? 'all' : 'some'
    },
    selectedDivision () {
      return this.selectedMatch && this.selectedMatch.ref.division
    },
    selectedDay () {
      return this.selectedMatch && this.selectedMatch.ref.round
    },
    exportData () {
      return this.tournament.fullTimeline.filter(f => !!(f.court && f.unix))
        .map(m => {
          return {
            Home: m.homeTeam.name,
            Away: m.awayTeam.name,
            Date: moment.unix(m.unix).format('L'),
            Time: m.time,
            Court: m.court,
            Division: m.division
          }
        })
    }

  },
  methods: {
    checkLowlight (m) {
      if (this.selectedTeams.length === 0) return false
      return m.m.allTeamIds && !m.m.allTeamIds.filter(f => this.selectedTeams.includes(f)).length > 0
    },
    toggleCourt (c) {
      if (this.hideCourts.includes(c)) {
        this.hideCourts = this.hideCourts.filter(f => f !== c)
      } else {
        this.hideCourts.push(c)
      }
    },
    toggleTime (t) {
      if (this.hideTimes.includes(t)) {
        this.hideTimes = this.hideTimes.filter(f => f !== t)
      } else {
        this.hideTimes.push(t)
      }
    },
    addCourt () {
      this.courtAdd = this.matchDialog = true
    },
    selectDay () {
      if (this.$route.query.day) {
        const d = moment(this.$route.query.day)
        if (d.isValid) {
          const x = this.days.find(f => f === d.format('MMM Do YYYY'))
          if (x) {
            this.day = x
            this.$router.replace({ query: null })
            return
          }
        }
      }
      if (!this.day && this.days.length) {
        this.day = this.days[0]
      }
    },
    selectMatch (match) {
      this.selectedMatch = match
      this.matchDialog = true
    },
    createMatch (slot, time) {
      this.selectedCourt = slot.court
      this.selectedSlot = time.time
      this.matchCreatotDialog = true
    },
    cancel () {
      this.selectedMatch = this.editable = null
      this.matchDialog = false
    },
    onSaveClick () {
      if (this.dirty) {
        this.loading = true
        this.$VBL.post.matches([this.editable.dto])
          .then(r => { this.$emit('match-saved') })
          .catch(err => console.log(err.response))
          .finally(() => {
            this.loading = false
            this.cancel()
          })
      }
    },
    onLoadingChange (v) {
      this.persist = v
    },
    onMatchSaved () {
      this.matchDialog = false
      this.selectedMatch = null
    },
    applyGroupFilter () {
      if (this.selectedGroups.length === 0) {
        this.hideCourts = []
        return
      }

      const show = flatten(this.selectedGroups.map(m => m.courts))
      this.hideCourts = this.allCourts.filter(f => !show.includes(f))
    },
    selectCourt (court, i) {
      this.selectedCourtName = { court: court, i: i }
      this.$refs.courtNameEdit.dialog = true
    },
    onDownloadClick2 () {
      console.log(this.exportData)
    },
    onDownloadClick () {
      var fileName = `${this.tournament.name.replace(/ /g, '_')}_Full_Schedule.csv`
      var data = this.exportData
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
  components: {
    MiniMatchCard,
    MatchUpCreator,
    MatchImportDialog,
    FullMatchCard,
    CourtNameEditor,
    CourtGrouper,
    AdvancedCourtManager
  },
  watch: {
    days: 'selectDay',
    selectedGroups: 'applyGroupFilter'
  },
  mounted () {
    // if (this.user && this.user.id === 1) this.window = 0
    this.selectDay()
  }
}
</script>

<style scoped>
>>> .v-timeline-item__body .row .col {
  padding:  4px 4px 0 4px;
}
>>> .v-timeline-item__dividerXX {
  max-width: 30px !important;
  min-width: 30px !important;
}
</style>
